<template>
<div id="">
  <div class="search-row">
    <Navbar navbarClass="transparent"></Navbar>
    <v-container grid-list-xs,sm,md,lg,xl>
      <v-layout  wrap justify-center>
        <h1 class="display-3 blog-title">
            Careers
          </h1>
      </v-layout>
    </v-container>
  </div>
  <v-container grid-list-xl>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <h3 class="title about-us">We're looking for you!</h3>
      </v-flex>

      <v-flex md8 sm9 xs10>
        <div class="">
          <h6 class="subheading">Open jobs</h6>
        </div>
      </v-flex>
        <v-container grid-list-sm>
          <v-layout  wrap>
            <v-flex xs3 v-for="(job, index) in this.jobs" :key="index">
              <v-card :href="job.path" hover>
                <v-card-title class="fixedHeight" primary-title >
                  {{job.name}}
                </v-card-title>
                <v-card-actions>
                  <v-btn flat color="primary">see details</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

          </v-layout>

        </v-container>
        <v-flex md8 sm9 xs10>
        <div class="">
          Feel free to <a href="mailto:hello@myroomabroad.com">drop us a message</a>
          on <a href="mailto:hello@myroomabroad.com">hello@myroomabroad.com</a>
          if you are interested in working with us.
        </div>
        <div class="">
          <v-btn color="primary" href='/aboutUs'>Learn more about us</v-btn>

        </div>
      </v-flex>
    </v-layout>

  </v-container>

</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    jobs:[
      {
        path:"/careers/developer",
        name:"Full stack NodeJS developer"
      },
      {
        path:"/careers/twsupply",
        name:"Chinese-English speaking supply manager"
      },
      {
        path:"/careers/JPSupply",
        name:"Japanese-English speaking supply manager"
      },
      {
        path:"/careers/brandAmbassador",
        name:"Student Brand Amabassador"
      }
    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:4em
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.careers div {
    margin: 1rem 0;
}
</style>
