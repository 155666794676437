<template lang="html">
<div id="">
  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout   justify-center wrap>
      <v-flex xs11 md7>
        <v-img src="https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1400,f_auto,q_auto/v1539347056/Logo/logo_horizontal_LARGE_TXT_no_bg.png"></v-img>
      </v-flex>
      <v-flex xs11  class="text-xs-center">
        <h1 class='display-2 ma-3'>Full Stack Node JS developer & CTO</h1>
      </v-flex>
      <v-flex xs11 md7>
        <v-layout  wrap>
          My Room Abroad is currently looking for a Full Stack Node JS Developer. As a housing platform we need are motivated Full Stack Developer & CTO to create, maintain, and administer all aspects of our platform.
        </v-layout>
        <v-layout  wrap>
          My Room Abroad is a young start up helping Internationals find a Room Abroad. We thrive ourselves in making international students stay abroad a life changing and unforgettable experience.
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          Job description:
        </v-layout>
        <v-layout  wrap>
          <ul>
            <li>Build, Manage & Maintain the tech.</li>
            <li>Write cloud functions.</li>
            <li>Write server-side code</li>
          </ul>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          What we are looking for:
        </v-layout>
        <v-layout  wrap>
          <ul>
            <li>Fluent in English (any other language is a plus)</li>
            <li>A Highly motivated, not afraid to take initiatives and a driven person</li>
            <li>The ability to work both independently and under pressure </li>
            <li>Experience in NodeJS, VUEJS, Vuetify framework, </li>
          </ul>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          Can only apply those who:
        </v-layout>
        <v-layout  wrap>
          <ol>
            <li>are available for full time </li>
            <li>have relevant skills and interests</li>
          </ol>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          What we offer:
        </v-layout>
        <v-layout  wrap>
          <ol>
            <li>Working in an international and fast growing start up</li>
            <li>Flexible work hours 5 days a week </li>
            <li>Salary in function of relevant experience</li>
            <li>Startup option plan in function of relevant experience</li>
          </ol>
        </v-layout>
        <v-layout  wrap>
          <v-btn color="primary" flat href='/careers'>See other job openings</v-btn>
        </v-layout>
        <v-layout  wrap>
          <span>If interested, please send your CV and Cover Letter to <a href="mailto:hello@myroomabroad.com">hello@myroomabroad.com</a></span>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
