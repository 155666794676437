<template lang="html">
  <div class="">

  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout   justify-center wrap>
      <v-flex xs11 md7>
        <v-img src="https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1400,f_auto,q_auto/v1539347056/Logo/logo_horizontal_LARGE_TXT_no_bg.png"></v-img>
      </v-flex>
      <v-flex xs11 class="text-xs-center">
        <h1 class='display-2 ma-3'>Brand Amabassador</h1>
      </v-flex>
      <v-flex xs11 md7>
        <v-layout  wrap>
          Wanna do something during your exchange while having a lot of fun?
        </v-layout>
        <v-layout  wrap>
          Become ambassador for My Room Abroad!
        </v-layout>
        <v-layout  wrap>
          My Room Abroad is a young start up helping Internationals find a Room Abroad.  We thrive ourselves in making international students stay abroad a life changing and unforgettable experience.
        </v-layout>
        <v-layout  wrap>
          As an ambassador, your responsibilities will be:
        </v-layout>
        <v-layout  wrap>
          <ul>
            <li>To advertise to your fellow exchange students through social media all My Room Abroad’s events such as pre-drinks, Barbecues, students’ parties, etc. to your fellow exchange students</li>
            <li>To advertise and co-organize trips (camping trips, day trip, hiking, snorkeling…)</li>
          </ul>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          What we are looking for:
        </v-layout>
        <v-layout  wrap>
          <ul>
            <li>Fluent in English (any other language is a plus)</li>
            <li>Someone sociable and easy going</li>
            <li>Autonomous and not afraid to take initiatives</li>
          </ul>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          Can only apply those who:
        </v-layout>
        <v-layout  wrap>
          <ol>
            <li>Are students </li>
            <li>Will stay for at least a semester</li>
            <li>Are willing to get the most out of their exchange!</li>
          </ol>
        </v-layout>
        <v-layout  wrap class="headline pa-2">
          What we offer:
        </v-layout>
        <v-layout  wrap>
          <ol>
            <li>Being part of an international team</li>
            <li>Access & free entrances to exclusive events</li>
            <li>Compensation based on motivation and commitment to the job</li>
          </ol>
        </v-layout>
        <v-layout  wrap>
          <v-btn color="primary" flat href='/careers'>See other job openings</v-btn>
        </v-layout>
        <v-layout  wrap>
          <span>If interested, please send your CV and Cover Letter to <a href="mailto:hello@myroomabroad.com">hello@myroomabroad.com</a></span>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
