<template lang="html">
<div id="">
  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout   justify-center wrap>
      <v-flex xs11 md7>
        <v-img src="https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_1400,f_auto,q_auto/v1539347056/Logo/logo_horizontal_LARGE_TXT_no_bg.png"></v-img>
      </v-flex>
      <v-flex xs11>
        <h1 class='display-2 ma-3 text-xs-center' >Japanese &amp; English speaking Supply
Manager</h1>
      </v-flex>
      <v-flex xs11 md7>
        <v-layout  wrap>
          My Room Abroad is currently looking for a Supply Manager to help us grow. As a housing platform we need more room offer. We are therefore looking for someone who can help us find this offer.
        </v-layout>
        <v-layout wrap>
          At My Room abroad, you would work in an international environment, and have responsibilities from day 1!
        </v-layout>
        <v-layout wrap>
          My Room Abroad is a young start up helping Internationals find a Room Abroad.  We thrive ourselves in making international students stay abroad a life changing and unforgettable experience.
        </v-layout>
        <v-layout wrap class="headline pa-2">
          Job description:
        </v-layout>
        <v-layout wrap>
          <ul>
            <li>Looking through any means possible for Japanese flat owners or flat managers.</li>
            <li>Cold calling.</li>
            <li>Leading meetings with landlords or flat manager, convince them to add their rooms on the My Room Abroad platform and close deals with them</li>
          </ul>
        </v-layout>
        <v-layout wrap class="headline pa-2">
          What we are looking for:
        </v-layout>
        <v-layout wrap>
          <ul>
            <li>Native Japanese speaker AND fluent in English (any other language is a plus)</li>
            <li>Someone autonomous and not afraid to take initiatives</li>
            <li>At ease with cold calling and leading meetings in Japanese.</li>
            <li>Sales background is a plus</li>
          </ul>
        </v-layout>
        <v-layout wrap class="headline pa-2">
          Can only apply those who:
        </v-layout>
        <v-layout wrap>
          <ol>
            <li>are available for full time </li>
            <li>have relevant skills and interests</li>
            <li>are comfortable working autonomously</li>
          </ol>
        </v-layout>
        <v-layout wrap class="headline pa-2">
          What we offer:
        </v-layout>
        <v-layout wrap>
          <ol>
            <li>An experience in sales in an international and fast growing start up</li>
            <li>Flexible work hours 5 days a week </li>
            <li>Salary in function of relevant experience</li>
          </ol>
        </v-layout>
        <v-layout wrap>
          <v-btn color="primary" flat href='/careers'>See other job openings</v-btn>
        </v-layout>
        <v-layout wrap>
            <span>If interested, please send your CV and Cover Letter to <a href="mailto:hello@myroomabroad.com">hello@myroomabroad.com</a></span>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
